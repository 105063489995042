import { jwtDecode } from 'jwt-decode'; // Ensure correct import based on actual usage
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userRole, setUserRole] = useState('');

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			setIsLoggedIn(true);
			try {
				const decoded = jwtDecode(token);
				setUserRole(decoded.role); // Assuming the role is encoded in the token
			} catch (error) {
				console.error('Error decoding token:', error);
			}
		} else {
			setIsLoggedIn(false);
			setUserRole('');
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{ isLoggedIn, setIsLoggedIn, userRole, setUserRole }}
		>
			{children}
		</AuthContext.Provider>
	);
};
