import ButtonSelection from '../../components/ButtonSelection';
import ClientInfo from '../subsections/ClientInfo';

import CheckboxComponent from '../../components/CheckboxComponent';
import InputComponent from '../../components/InputComponent';

// SCSS
import '../../assets/scss/sectionOne.scss';

const SectionOne = ({
	control,
	register,
	handleLocationSelect,
	handleChange,
	formData,
	setValue,
	preselectedLocation,
}) => {
	const localHandleLocationSelect = (location) => {
		handleLocationSelect(location);
		setValue('selectedLocation', location);
	};

	return (
		<div className='sectionOne flex-col gap-40'>
			<ClientInfo control={control} register={register} />
			<div className='flex-row norgesgruppen'>
				<CheckboxComponent
					label={
						<span>
							Er dette et <b>Norgesgruppen</b>-Selskap?
						</span>
					}
					name='norgesgruppenCheck'
					value='Norgesgruppen'
					className='norgesgruppenCheck'
					subContext='NG tillater ikke retargeting. Dvs. Vi kan ikke
					målrette annonsene mot folk som har kikket
					på tilsvarende annonser, eller folk som har
					sett annonsen en gang, men enda ikke søkt. Vi
					kan kun benytte oss av mer generiske
					målgrupper, basert på demografisk data som
					kjennetegner målgruppen vi ønsker å nå'
					register={register}
				/>
			</div>
			<div className='sok flex-row'>
				<CheckboxComponent
					label={
						<span>
							Search <b>AC</b>
						</span>
					}
					name='searchCheck'
					value='Søk'
					className='searchCheck'
					subContext='Search igangsettes av AC parallelt med kampanjen'
					register={register}
				/>
			</div>

			<div className='flex-row stillBtn'>
				<div className='posTitle'>
					<InputComponent
						label='Stillingstittel (i annonsen)'
						type='text'
						name='stillingstittel'
						required
						register={register}
						onChange={(e) =>
							handleChange(e.target.name, e.target.value)
						}
					/>
				</div>

				<div className='flex-col'>
					<label>Velg kontor:</label>
					<ButtonSelection
						onSelect={localHandleLocationSelect}
						preselectedLocation={preselectedLocation}
					/>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
