import InputComponent from '../../components/InputComponent';

const ClientInfo = ({ register }) => {
	return (
		<div className='clientInfo flex flex-row'>
			<div>
				<InputComponent
					label='Firmanavn kunde'
					type='text'
					name='companyName'
					required
					register={register}
				/>
			</div>
			<div>
				<InputComponent
					label='Navn på bestiller AC'
					type='text'
					name='ordererName'
					required
					register={register}
				/>
			</div>
			<div>
				<InputComponent
					label='Epost til bestiller AC'
					type='email'
					name='ordererEmail'
					required
					register={register}
				/>
			</div>
			<div>
				<InputComponent
					label='Telefon'
					type='tel'
					name='ordererPhone'
					required
					register={register}
				/>
			</div>
		</div>
	);
};

export default ClientInfo;
