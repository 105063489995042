import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { hasRole, isLoggedIn } from '../utils/auth';
import LogoutButton from './LogoutButton';

import { FaUserCircle } from 'react-icons/fa';

// Import images
import aclogo from '../assets/images/aclogo.svg';
import adrlogo from '../assets/images/alogo.svg';
import pmedia from '../assets/images/logo_kvadratisk_polarismedia.png';

// Icons
import { FiPhoneCall } from 'react-icons/fi';

const Header = () => {
	// Destructure isLoggedIn and userRole from the context
	const { isLoggedIn, userRole } = useAuth();
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	// Toggle the dropdown
	const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
	const dropdownRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsDropdownVisible(false);
			}
		}

		// Only add the event listener if the dropdown is visible
		if (isDropdownVisible) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		// CLeanup the event listener
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropdownVisible]);

	console.log({ isLoggedIn, userRole });

	return (
		<div className='main-header'>
			<div className='header-content'>
				<div className='ac-logo'>
					<img src={aclogo} alt='' />
				</div>
				<div className='menuItems'>
					<ul>
						<li>
							<Link to='/'>Skjema</Link>
						</li>
						{userRole === 'admin' && (
							<li>
								<Link to='/admin'>Admin</Link>
							</li>
						)}
					</ul>
				</div>
				<div className='userIcon'>
					<ul>
						{isLoggedIn ? (
							<>
								<li>
									<Link to='/profile'>Profil</Link>
								</li>
								<li>
									<Link to='/orders'>Ordrer</Link>
								</li>
								{userRole === 'admin' && (
									<li>
										<Link to='/register'>Registrer</Link>
									</li>
								)}
								<li>
									<LogoutButton />
								</li>
							</>
						) : (
							<li>
								<Link to='/login'>Logg inn</Link>
							</li>
						)}
					</ul>
				</div>
				<div className='alogo-section'>
					<div className='contact'>
						<h2>Janne Løvseth</h2>
						<div>
							<FiPhoneCall size='16' />{' '}
							<a href='tel:97715766'>977 15 766</a>
						</div>
					</div>
					<div className='border'></div>
					<img src={pmedia} alt='pmedia' />
				</div>
			</div>
		</div>
	);
};

export default Header;
