import CheckboxComponent from '../../components/CheckboxComponent';
import InputComponent from '../../components/InputComponent';
import TextareaComponent from '../../components/TextareaComponent';

// SCSS
import '../../assets/scss/sectionFour.scss';

const SectionFour = ({ register, formData, handleChange }) => {
	return (
		<div className='sectionFour'>
			<div className='imageVideoAd flex-col'>
				<div className='content'>
					<h2>Animert annonse eller stillbildevideo?</h2>
					<div className='border'></div>
				</div>
				<div className='flex-row checkboxes'>
					<CheckboxComponent
						label='Stillbildevideo'
						name='stillbildevideo'
						value='Stillbildevideo'
						className='stillbildevideo'
						register={register}
						checked={formData['stillbildevideo']}
					/>
					<CheckboxComponent
						label='Animert annonse'
						name='animatedAd'
						value='Animert annonse'
						className='animatedAd'
						register={register}
						checked={formData['animatedAd']}
					/>
				</div>
				<div className='flex-row vidExamples'>
					<small>
						Eksempel stillbildevideo{' '}
						<a
							target='_blank'
							href='https://vimeo.com/907396668/ee8daf05ab?share=copy'
							rel='noreferrer'
						>
							Eksempel stillbildevideo
						</a>
					</small>

					<small>
						Eksempel animert annonse{' '}
						<a
							target='_blank'
							href='https://www.facebook.com/watch/?v=1081570032981454'
							rel='noreferrer'
						>
							Eksempel animert
						</a>
					</small>
				</div>

				<TextareaComponent
					label='Tekst til siste plakat i video'
					name='lastPoster'
					className='last-poster mt-8'
					placeholder='Vil du vite mer om stilling i Selskap?'
					cols='40'
					rows='4'
					required
					register={register}
					counter={false}
				/>

				{formData.stillbildevideo && (
					<div className='stillSlides flex-col'>
						<h3 className='mt-4'>
							Ved stillbildevideo, skriv inn tekst til video her.
							Maks en setning per "slide" - totalt 3 slides. Jo
							mindre tekst, jo bedre
						</h3>

						<div className='flex-col'>
							<InputComponent
								type='text'
								name='stillbildevideo-slide1'
								register={register}
								className='slides'
								placeholder='Slide 1...'
							/>
							<InputComponent
								type='text'
								name='stillbildevideo-slide2'
								register={register}
								className='slides'
								placeholder='Slide 2...'
							/>
							<InputComponent
								type='text'
								name='stillbildevideo-slide3'
								register={register}
								className='slides'
								placeholder='Slide 3...'
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SectionFour;
