import 'chart.js/auto'; // Import to register the controller and chart type.
import React from 'react';
import { Bar } from 'react-chartjs-2';

const data = {
	labels: [
		'Pakke Basis',
		'Pakke medium liten og stor',
		'Premium Pakke',
		'Stillbildevideo',
		'Animert annonse',
		'Annonser med hjemmekontor',
	],
	datasets: [
		{
			// label: 'Statistikk denne mnd',
			data: [50, 32, 68, 27, 63, 21],
			backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 159, 64, 1)',
			],
			borderWidth: 1,
		},
	],
};

const options = {
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		y: {
			beginAtZero: true,
		},
	},
};

const BarChartComponent = () => {
	return <Bar data={data} options={options} />;
};

export default BarChartComponent;
