import React, { useEffect, useState } from 'react';

const Orders = () => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const [orders, setOrders] = useState([]); // Initial state as an empty array

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const response = await fetch(`${apiUrl}/pdfs/all-pdfs`);
				const data = await response.json();
				if (Array.isArray(data)) {
					setOrders(data);
				} else {
					console.error('Fetched data is not an array:', data);
				}
			} catch (error) {
				console.error('Error fetching orders:', error);
			}
		};

		fetchOrders();
	}, []);

	return (
		<div className='orderData'>
			<h1>Alle ordre sendt inn</h1>
			<div className='ordersList'>
				{Array.isArray(orders) && orders.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>PDF</th>
							</tr>
						</thead>
						<tbody>
							{orders.map((order) => (
								<tr key={order.id}>
									<td>{order.name}</td>
									<td>{order.email}</td>
									<td>
										<a
											href={`data:application/pdf;base64,${order.file_data}`}
											download={`Order_${order.id}.pdf`}
											target='_blank'
											rel='noopener noreferrer'
										>
											View PDF
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<p>No orders found.</p>
				)}
			</div>
		</div>
	);
};

export default Orders;
