import Logo from '../../../assets/images/aclogo.svg';

// SCSS
import '../../../assets/scss/Admin/AdminMenu.scss';
const AdminMenu = ({ onMenuItemClick }) => {
	// Define your menu items here
	const menuItems = [
		{ key: 'skjema', label: 'Tilbake til skjema' },
		{ key: 'dashboard', label: 'Oversikt' },
		{ key: 'users', label: 'Brukere' },
		{ key: 'register', label: 'Registrer bruker' },
		// ... other menu items
	];

	return (
		<div className='adminMenu'>
			<div className='adminMenu__container'>
				<div
					className='logo'
					style={{ backgroundImage: `url(${Logo})` }}
				></div>
				<nav>
					<ul>
						{menuItems.map((item) => (
							<li
								key={item.key}
								onClick={() => onMenuItemClick(item.key)}
							>
								- {item.label}
							</li>
						))}
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default AdminMenu;
