import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	additionalServicesBasedOnLocation,
	commonPackages,
} from '../data/servicesData';

// subsections
import Footer from '../sections/pageSections/footer';
import FormContent from './subsections/formContent';

const SalesForm = () => {
	const navigate = useNavigate();
	const { handleSubmit, control, register, setValue, getValues, watch } =
		useForm();
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedServices, setSelectedServices] = useState({});
	const [userDepartment, setUserDepartment] = useState('');

	const apiUrl = process.env.REACT_APP_API_URL;
	console.log('API URL:', apiUrl);

	useEffect(() => {
		const department = localStorage.getItem('userDepartment');
		if (department) {
			setUserDepartment(department);
			setSelectedLocation(department);
		}
	}, []);

	const formData = watch();

	const handleLocationSelect = (location) => {
		setSelectedLocation(location);
		console.log(location);
	};

	const handleServiceChange = (serviceId, subPackageId = null, isChecked) => {
		setSelectedServices((prev) => {
			const currentServiceDetails = prev[serviceId] || {};
			if (subPackageId) {
				// Handle sub-package toggle
				const currentSubPackages =
					currentServiceDetails.subPackages || {};
				currentSubPackages[subPackageId] = isChecked;
				currentServiceDetails.subPackages = currentSubPackages;
			} else {
				// Handle service checkbox toggle
				currentServiceDetails.isChecked = isChecked;
			}
			return { ...prev, [serviceId]: currentServiceDetails };
		});
	};
	console.log('Selected Services State:', selectedServices);

	const onSubmit = async (data) => {
		const packageChoice = data.packageChoice;

		console.log('Final selected services state:', selectedServices);
		const findServiceOrSubPackageById = (serviceId) => {
			for (const location of Object.values(
				additionalServicesBasedOnLocation
			)) {
				for (const packages of Object.values(location)) {
					const foundService = packages.find(
						(service) => service.id === serviceId
					);
					if (foundService) return foundService;
				}
			}

			for (const packages of Object.values(commonPackages)) {
				const foundService = packages.find(
					(service) => service.id === serviceId
				);
				if (foundService) return foundService;
				for (const service of packages.filter(
					(service) => service.subPackages
				)) {
					const foundSubPackage = service.subPackages.find(
						(sub) => sub.id === serviceId
					);
					if (foundSubPackage) return foundSubPackage;
				}
			}

			return null;
		};

		const selectedServicesDetails = Object.entries(selectedServices)
			.filter(
				([_, details]) =>
					details.isChecked ||
					Object.values(details.subPackages || {}).some((v) => v)
			)
			.map(([serviceId, details]) => {
				const service = findServiceOrSubPackageById(serviceId);
				console.log('Processing service:', serviceId, details);
				if (!service) {
					console.error('Service not found:', serviceId);
					return { name: '', subPackages: [] };
				}

				const subPackageNames = Object.entries(
					details.subPackages || {}
				)
					.filter(([_, isChecked]) => isChecked)
					.map(([subPackageId, _]) => {
						const subPackage =
							service.subPackages &&
							service.subPackages.find(
								(sub) => sub.id === subPackageId
							);
						if (!subPackage) {
							console.error(
								'Sub-package not found:',
								subPackageId,
								'in service',
								serviceId
							);
						}
						return subPackage ? subPackage.name : undefined;
					})
					.filter((name) => name);

				return { name: service.name, subPackages: subPackageNames };
			});

		console.log('Selected services details:', selectedServicesDetails);

		const submissionData = {
			...data,
			additionalServices: selectedServices,
		};

		console.log('Submission Data:', submissionData);

		try {
			const formData = new FormData();

			formData.append('formData', JSON.stringify(submissionData));

			const files = getValues('companyProfile');
			files.forEach((file) => formData.append('companyProfile', file));

			const response = await fetch(`${apiUrl}/submit-form`, {
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log('Success:', responseData);
			} else {
				console.error('Could not send form:', response.statusText);
			}
		} catch (error) {
			console.error('Could not send form:', error);
		}
	};

	const onTest = () => {
		navigate('/review');
	};

	const handleChange = (name, value) => {
		setValue(name, value);
		console.log(formData);
	};

	return (
		<form className='w-2/3 mx-auto' onSubmit={handleSubmit(onSubmit)}>
			<FormContent
				control={control}
				register={register}
				handleChange={handleChange}
				formData={formData}
				setValue={setValue}
				selectedLocation={selectedLocation}
				handleLocationSelect={handleLocationSelect}
				selectedServices={selectedServices}
				setSelectedServices={setSelectedServices}
				getValues={getValues}
				preselectedLocation={userDepartment}
			/>
			<input type='hidden' {...register('selectedLocation')} />
			<button type='button' className='testSending_btn' onClick={onTest}>
				Send test (Sendes ingen epost)
			</button>
			<Footer />
		</form>
	);
};

export default SalesForm;
