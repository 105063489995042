import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

// SCSS
import '../../assets/scss/loginForm.scss';

// Images
import { set } from 'react-hook-form';
import adrBygg from '../../assets/images/adr_bygg.jpg';

const LoginForm = () => {
	const navigate = useNavigate();
	const { setIsLoggedIn, setUserRole } = useAuth();

	const [userPass, setUserPass] = useState('password');
	const [loggedIn, setLoggedIn] = useState(false);
	const [mode, setMode] = useState('login');

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const [loadingMessage, setLoadingMessage] = useState('');
	const [isRedirecting, setIsRedirecting] = useState(false); // New state

	const apiUrl = process.env.REACT_APP_API_URL;

	const showPass = (e) => {
		e.preventDefault();
		if (userPass === 'password') {
			setUserPass('text');
		} else {
			setUserPass('password');
		}
	};

	useEffect(() => {
		let count = 0;
		if (isRedirecting) {
			// Changed condition
			// Start animation
			const interval = setInterval(() => {
				setLoadingMessage(`Videresender${'.'.repeat(count % 4)}`);
				count++;
			}, 500);

			// Set a delay before navigating
			const timeout = setTimeout(() => {
				clearInterval(interval); // Stop the animation
				navigate('/'); // Navigate to the form
			}, 4000);

			return () => {
				clearInterval(interval);
				clearTimeout(timeout);
			};
		}
	}, [isRedirecting, navigate]); // Changed dependency

	const handleSubmit = async (event) => {
		event.preventDefault();
		const loginPayload = {
			login: username, // This could be an email or a username
			password: password,
		};

		const url =
			mode === 'login'
				? `${apiUrl}/auth/login`
				: `${apiUrl}/auth/reset-password`;
		const method = mode === 'login' ? 'POST' : 'POST';
		const body =
			mode === 'login'
				? JSON.stringify({ login: username, password })
				: JSON.stringify({ username });

		try {
			const response = await fetch(url, {
				method: method,
				headers: {
					'Content-Type': 'application/json',
				},
				body: body,
			});
			const data = await response.json();
			if (response.ok) {
				console.log('Response is OK');
				if (mode === 'login') {
					localStorage.setItem('token', data.token);
					setIsLoggedIn(true);
					setLoggedIn(true); // Set logged in state
					setIsRedirecting(true); // Trigger redirection state
				} else {
					setErrorMessage(
						'Please check your email to reset your password'
					);
				}
			} else {
				console.log('Errors here');
				setErrorMessage(data.message);
			}
		} catch (error) {
			console.error('Error:', error);
			setErrorMessage('Failed to process your request');
		}
	};

	return (
		<div
			className='loginForm'
			style={{ backgroundImage: `url(${adrBygg})` }}
		>
			<div className='formContainer'>
				<h2>
					{mode === 'login' ? ' Logg inn' : 'Tilbakestill passord'}
				</h2>
				<div className='form'>
					<form onSubmit={handleSubmit}>
						<div>
							<input
								type='text'
								placeholder='Epost / Brukernavn'
								name='username'
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								id='emailUser'
							/>
						</div>
						{mode === 'login' && (
							<div className='passField'>
								<input
									type={userPass}
									placeholder='*******'
									name='password'
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								<button
									type='button'
									onClick={showPass}
									aria-label={
										userPass === 'password'
											? 'Show password'
											: 'Hide password'
									}
								>
									{userPass === 'password' ? 'Vis' : 'Skjul'}
								</button>
							</div>
						)}
						<input
							type='submit'
							value={
								mode === 'login'
									? 'Logg inn'
									: 'Tilbakestill passord'
							}
						/>
						<button
							type='button'
							className='forgotPass_btn'
							onClick={() =>
								setMode(
									mode === 'login'
										? 'forgotPassword'
										: 'login'
								)
							}
						>
							{mode === 'login'
								? 'Glemt passord'
								: 'Tilbake til innlogging'}
						</button>
					</form>
					{errorMessage && <p>{errorMessage}</p>}
					{loadingMessage && <p>{loadingMessage}</p>}
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
