import React from 'react';
import BarChartComponent from './data/dashChartData';

// Icons
import { BsGraphUp } from 'react-icons/bs';
import { FaRegMoneyBillAlt, FaWpforms } from 'react-icons/fa';
import { LuUser2 } from 'react-icons/lu';
import { RiAdminLine } from 'react-icons/ri';

// SCSS
import '../../../assets/scss/Admin/Dashboard.scss';

const Dashboard = () => {
	const stats = {
		users: 120,
		userRoleUser: 100,
		userRoleAdmin: 20,
		ordersSent: 150,
		formsMonth: 27,
		mostFirm: 'The Assessment Company',
		newUsersMonth: 7,
		userMostOrders: 'Ola Nordmann',
	};

	return (
		<div className='dashboard'>
			<h3>Du er nå inne i Admin oversikten</h3>
			<div className='dashboard__content'>
				<div className='topStats'>
					<div className='stats-card'>
						<div className='col'>
							<LuUser2 size='32' />
							<h3>Brukere registrert</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.users}</p>
						</div>
					</div>

					<div className='stats-card'>
						<div className='col'>
							<RiAdminLine size='32' />
							<h3>Admin brukere</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.userRoleAdmin}</p>
						</div>
					</div>

					<div className='stats-card'>
						<div className='col'>
							<FaWpforms size='32' />
							<h3>Ordre sendt</h3>
						</div>
						<div className='stats-number'>
							<p>{stats.ordersSent}</p>
						</div>
					</div>
				</div>

				<div className='middleStats'>
					<div className='middleStats__content row'>
						<div className='dailyReports'>
							<div className='top row'>
								<div className='col'>
									<h3>Månedlig rapport</h3>
									<p>The Assessment Company</p>
								</div>
								<BsGraphUp size='32' />
							</div>
							<div className='dailyReports__content'>
								<BarChartComponent />
							</div>
						</div>

						<div className='statsReport'>
							<div className='top row'>
								<div className='col'>
									<h3>Statistikk denne mnd</h3>
									<p>The Assessment Company</p>
								</div>
								<FaRegMoneyBillAlt size='32' />
							</div>
							<div className='statsReport__content'>
								<div className='row'>
									<div className='col'>
										<div className='col new-item'>
											<h3>Antall skjema</h3>
											<p>{stats.formsMonth}</p>
										</div>
										<div className='col new-item'>
											<h3>Nye brukere</h3>
											<p>{stats.newUsersMonth}</p>
										</div>
									</div>
									<div className='col'>
										<div className='col new-item'>
											<h3>Største firma</h3>
											<p>{stats.mostFirm}</p>
										</div>
										<div className='col new-item'>
											<h3>Flest bestillinger</h3>
											<p>{stats.userMostOrders}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
