import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/reviewModal';
import { getUserId } from '../../utils/auth'; // Import the new function

// SCSS
import '../../assets/scss/review.scss';

// ICONS
import { TiStarFullOutline } from 'react-icons/ti';

const StarRating = ({ count, value, onChange }) => {
	const stars = Array.from({ length: count }, (_, index) => (
		<TiStarFullOutline
			size='42'
			key={index}
			className={index < value ? 'star filled' : 'star'}
			onClick={() => onChange(index + 1)}
		/>
	));

	return <div className='star-items'>{stars}</div>;
};

const Review = () => {
	const [rating1, setRating1] = useState(0);
	const [rating2, setRating2] = useState(0);
	const [reviewText, setReviewText] = useState('');
	const [userId, setUserId] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const apiUrl = process.env.REACT_APP_API_URL;

	const navigate = useNavigate();

	useEffect(() => {
		// Get the user ID from the token
		const id = getUserId();
		setUserId(id);
	}, []);

	const handleExit = (e) => {
		e.preventDefault();
		navigate('/');
	};

	const handleCloseModal = () => {
		setShowModal(false);
		navigate('/'); // Redirect or do something else on close
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// Ensure userId is correctly passed and not null
			console.log('UserId:', userId);
			const response = await axios.post(
				`${apiUrl}/reviews/submit-review`,
				{
					userId,
					ratingAnnonselosninger: rating1,
					ratingBestillingsskjema: rating2,
					reviewText,
				}
			);
			console.log('Review submitted successfully:', response.data);
			setShowModal(true); // Show the modal on success
		} catch (error) {
			console.error('Error submitting review:', error);
		}
	};

	return (
		<div className='container review'>
			<div className='header'>
				<h1>Takk for din bestilling!</h1>
				<div className='border'></div>
				<small>
					Hjelp oss til å bli bedre, har du inspill som gjør at AC og
					Polaris media sammen kan levere enda bedre produkter og
					løsninger fremover?
				</small>
			</div>

			<form onSubmit={handleSubmit}>
				<div className='star-containers'>
					<div className='star-containers__cont-1'>
						<h2>
							Hvor godt fornøyd er du med AC's Annonseløsninger ?
						</h2>
						<StarRating
							count={5}
							value={rating1}
							onChange={setRating1}
						/>
					</div>
					<div className='star-containers__cont-2'>
						<h2>
							Hvor godt fornøyd er du med bestillingsskjemaet ?
						</h2>
						<StarRating
							count={5}
							value={rating2}
							onChange={setRating2}
						/>
					</div>
				</div>

				<div className='text-review'>
					<h2>
						Har du innspill som gjør at AC of Adressa sammen kan
						levere enda bedre produkter og løsninger framover ?
					</h2>
					<textarea
						className='review-text'
						name='review-text'
						value={reviewText}
						onChange={(e) => setReviewText(e.target.value)}
					/>
				</div>

				<div className='review-btns'>
					<button type='submit' className='submit-review'>
						Send tilbakemelding
					</button>
					<button
						type='button'
						className='exit-review'
						onClick={handleExit}
					>
						Ikke nå
					</button>
				</div>
			</form>

			{/* Modal */}
			<Modal show={showModal} handleClose={handleCloseModal}>
				<h2>Takk for din tilbakemelding!</h2>
				<p>
					Vi setter stor pris på din tilbakemelding. Vi vil gå
					igjennom alle deres tilbakemeldinger periodisk for å
					forbedre våre tjenester.
				</p>
				<button onClick={handleCloseModal}>Tilbake til skjema</button>
			</Modal>
		</div>
	);
};

export default Review;
