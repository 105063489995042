import React, { useState } from 'react';
import '../assets/scss/orderPackages.scss';
import {
	additionalServicesBasedOnLocation,
	commonPackages,
} from '../data/servicesData';

import AdditionalServices from './additionalServices';

import CheckMark from '../assets/images/checkmark.svg';
import CrossMark from '../assets/images/crossmark.svg'; // Assuming you have this asset

const OrderPackages = ({
	name,
	register,
	setValue,
	service,
	options, // Make sure options include `includedServices` for each package
	formData,
	selectedLocation,
	selectedServices,
	setSelectedServices,
	getValues
}) => {
	const [selectedOption, setSelectedOption] = useState(formData[name]);
	const handleLabelClick = (optionValue) => {
		const newValue = optionValue === selectedOption ? null : optionValue;
		console.log('Directly setting value to:', newValue);
		setValue(name, newValue);
		setSelectedOption(newValue);
	};

	const getCombinedServices = (location, packageType) => {
		// Use the same common packages for Mediumliten and Mediumstor
		if (packageType === 'Mediumliten' || packageType === 'Mediumstor') {
			const commonServices = [...commonPackages['Mediumstor']]; // Clone the common packages
			const regionalServices =
				additionalServicesBasedOnLocation[location]?.[packageType] ||
				[];
			return [...commonServices, ...regionalServices]; // Combine them
		}

		// For Premium, just use the specific services for the location.
		if (packageType === 'Premium') {
			const commonPremiumServices = [...commonPackages['Premium']]; // Clone the common packages for Premium
			const regionalPremiumServices =
				additionalServicesBasedOnLocation[location]?.[packageType] ||
				[];
			return [...commonPremiumServices, ...regionalPremiumServices]; // Combine them
		}

		// Return empty array if no match (for safety)
		return [];
	};

	const servicesToShow =
		selectedLocation && selectedOption
			? getCombinedServices(selectedLocation, selectedOption)
			: [];

	return (
		<div className='order-packages'>
			<h2 className='sectionTitle'>Bestillingspakker</h2>
			<div className='border'></div>
			<div className='titleDesc'>
				<p>Sett sammen pakken som passer deg best</p>
			</div>
			<div className='order-packages__container'>
				{options.map((option, index) => {
					// Use selectedOption for immediate UI update
					const isSelected = selectedOption === option.value;
					return (
						<div key={index} className='radio-box'>
							<input
								type='radio'
								name={name}
								id={`${name}-${index}`}
								value={option.value}
								className='hidden-radio'
								{...register(name)}
								onClick={() => handleLabelClick(option.value)}
							/>
							<label
								htmlFor={`${name}-${index}`}
								className={`custom-radio ${
									isSelected ? 'selected' : ''
								}`}
							>
								<div className='content-box'>
									<div className='title'>{option.title}</div>
									<div className='border'></div>
									<ul className='description'>
										{option.service}
									</ul>
								</div>
								<div
									className={`order-status ${
										isSelected ? 'chosen' : 'order'
									}`}
								>
									{isSelected
										? 'Pakke valgt'
										: 'Bestill pakke'}
								</div>
							</label>
						</div>
					);
				})}
			</div>

			<AdditionalServices
				selectedPackage={selectedOption}
				services={servicesToShow}
				register={register}
				selectedServices={selectedServices}
				setSelectedServices={setSelectedServices}
				getValues={getValues}
			/>
		</div>
	);
};

export default OrderPackages;
