import React, { useState } from 'react';
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
} from 'react-router-dom';
import Header from './components/Header';
import SalesForm from './sections/Form';
import AdminPanel from './sections/pageSections/Admin/AdminPanel';
import Orders from './sections/pageSections/Orders';
import Review from './sections/pageSections/Review';
import RegisterForm from './sections/subsections/RegisterForm';
import SetPasswordForm from './sections/subsections/SetPasswordForm';
import LoginForm from './sections/subsections/loginForm';

import { AuthProvider } from './contexts/AuthContext';

import './assets/scss/main.scss';

const AppContent = () => {
	const location = useLocation();

	// Determine whether to show the header based on the route
	const showHeader = !location.pathname.startsWith('/admin');

	return (
		<div className='App'>
			{showHeader && (
				<header className='App-header'>
					<Header />
				</header>
			)}
			<div className={showHeader ? 'mainContent' : 'adminContent'}>
				<Routes>
					<Route path='/' element={<SalesForm />} />
					<Route path='/login' element={<LoginForm />} />
					<Route path='/register' element={<RegisterForm />} />
					<Route path='/set-password' element={<SetPasswordForm />} />
					<Route path='/admin' element={<AdminPanel />} />
					<Route path='/review' element={<Review />} />
					<Route path='/orders' element={<Orders />} />
					{/* ... other routes */}
				</Routes>
			</div>
		</div>
	);
};

function App() {
	return (
		<AuthProvider>
			<Router>
				<AppContent />
			</Router>
		</AuthProvider>
	);
}

export default App;
