import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CiTrash } from 'react-icons/ci';
import { FaPencilAlt } from 'react-icons/fa';
import '../../../assets/scss/Admin/Users.scss';
import ConfirmationModal from './confirmModal';

const Users = () => {
	const [users, setUsers] = useState([]);
	const [editingRoleId, setEditingRoleId] = useState(null);
	const [selectedRole, setSelectedRole] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [userToEdit, setUserToEdit] = useState(null);

	// Changing Email
	const [isEmailChangeModalOpen, setIsEmailChangeModalOpen] = useState(false);
	const [newEmail, setNewEmail] = useState('');
	const [confirmNewEmail, setConfirmNewEmail] = useState('');
	const [emailUpdateError, setEmailUpdateError] = useState('');

	//Password changing
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [passwordUpdateError, setPasswordUpdateError] = useState('');
	const [currentAction, setCurrentAction] = useState('');

	const apiUrl = process.env.REACT_APP_API_URL;

	// Delete user
	const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
		useState(false);
	const [deleteError, setDeleteError] = useState('');

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(`${apiUrl}/auth/users`);
				setUsers(response.data);
			} catch (error) {
				console.error('Error fetching users:', error);
			}
		};
		fetchUsers();
	}, []);

	// Delete a user
	const handleDeleteClick = (user) => {
		setUserToEdit(user);
		setIsDeleteConfirmationModalOpen(true);
	};

	const handleDeleteConfirm = async () => {
		try {
			await axios.delete(`${apiUrl}/auth/users/${userToEdit.id}`);
			setUsers(users.filter((user) => user.id !== userToEdit.id));
			setIsDeleteConfirmationModalOpen(false);
			setUserToEdit(null);
		} catch (error) {
			console.error('Error deleting user:', error);
			setDeleteError('Failed to delete the user.');
		}
	};

	// END Delete user

	// Change Email / Password handling of users
	const handleSelectChange = (e, user) => {
		const selectedAction = e.target.value;
		setUserToEdit(user);
		setCurrentAction(selectedAction);

		if (selectedAction === 'editEmail') {
			setIsEmailChangeModalOpen(true);
			setIsModalOpen(false);
		} else if (selectedAction === 'setPassword') {
			setNewPassword('');
			setConfirmNewPassword('');
			setPasswordUpdateError('');
			setIsEmailChangeModalOpen(false);
			setIsModalOpen(true);
		}
	};

	// Setting password of user
	const handlePasswordUpdateConfirm = async () => {
		if (newPassword !== confirmNewPassword) {
			setPasswordUpdateError('Passordene må være like.');
			return;
		}

		try {
			const response = await axios.patch(
				`${apiUrl}/auth/users/${userToEdit.id}/password`,
				{
					newPassword,
				}
			);

			console.log('Password updated:', response.data);
			// Close modal and reset states
			setIsModalOpen(false);
			setNewPassword('');
			setConfirmNewPassword('');
			setPasswordUpdateError('');
		} catch (error) {
			console.error('Error updating password:', error);
			setPasswordUpdateError(
				error.response?.data?.message ||
					'En feil oppstod ved oppdatering av passord.'
			);
		}
	};

	const handleEmailChangeConfirm = async () => {
		if (newEmail !== confirmNewEmail) {
			alert('Epostene må være like. Prøv igjen!');
			return;
		}
		if (!newEmail.trim()) {
			alert('Vennligst skriv inn en gyldig Epost adresse');
			return;
		}

		try {
			const response = await axios.patch(
				`${apiUrl}/auth/users/${userToEdit.id}/email`,
				{
					email: newEmail,
				}
			);
			console.log('Epost oppdatert:', response.data);

			// Update the users array to reflect the change in the UI
			setUsers(
				users.map((user) =>
					user.id === userToEdit.id
						? { ...user, email: newEmail }
						: user
				)
			);

			// Resrt modal and input states
			setIsEmailChangeModalOpen(false);
			setUserToEdit(null);
			setNewEmail('');
			setConfirmNewEmail('');
			setEmailUpdateError('');
		} catch (error) {
			console.error('Error updating Email:', error);
			const errorMessage =
				error.response?.data?.message ||
				'En feil oppstod ved oppdatering av e-post.';
			setEmailUpdateError(errorMessage);
		}
	};

	// End change Email handling of users

	// Change role for users
	const handleEditClick = (user) => {
		setUserToEdit(user);
		setSelectedRole(user.role);
		setEditingRoleId(user.id);
	};

	const handleRoleChange = (e) => {
		setSelectedRole(e.target.value);
		if (e.target.value !== userToEdit?.role) {
			setIsModalOpen(true);
		}
	};

	const handleConfirmRoleChange = async () => {
		setIsModalOpen(false);
		if (userToEdit && selectedRole !== userToEdit.role) {
			console.log('User to edit:', userToEdit);
			try {
				const response = await axios.patch(
					`${apiUrl}/auth/users/${userToEdit.id}/role`,
					{ role: selectedRole }
				);
				console.log('Update response:', response.data);
				setUsers(
					users.map((user) =>
						user.id === userToEdit.id
							? { ...user, role: selectedRole }
							: user
					)
				);
				setUserToEdit(null);
				setEditingRoleId(null);
			} catch (error) {
				console.error('Error updating role:', error);
			}
		}
	};
	// End Change role for users

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setIsEmailChangeModalOpen(false);
		setIsDeleteConfirmationModalOpen(false);
		setCurrentAction('');
	};

	return (
		<div className='userDashboard'>
			<h1>Registrerte brukere</h1>
			<div className='userDashboard__content'>
				<div className='users'>
					<table>
						<thead>
							<tr>
								<th>Navn</th>
								<th>Epost adresse</th>
								<th>Rolle</th>
								<th>Handling</th>
								<th>Slett bruker</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => (
								<tr key={index}>
									<td>
										{user.first_name} {user.last_name}
									</td>
									<td>{user.email}</td>
									<td>
										{editingRoleId === user.id ? (
											<select
												value={selectedRole}
												onChange={handleRoleChange}
												// onBlur={handleCancelRoleChange}
											>
												<option value='user'>
													User
												</option>
												<option value='admin'>
													Admin
												</option>
											</select>
										) : (
											<>
												{user.role}
												<FaPencilAlt
													onClick={() =>
														handleEditClick(user)
													}
												/>
											</>
										)}
									</td>
									<td>
										<select
											onChange={(e) =>
												handleSelectChange(e, user)
											}
										>
											<option>Velg handling</option>
											<option value='setPassword'>
												Set Password
											</option>
											<option value='editEmail'>
												Edit Email
											</option>
										</select>
									</td>
									<td>
										<CiTrash
											size='24'
											onClick={() =>
												handleDeleteClick(user)
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<ConfirmationModal
				isOpen={
					isModalOpen ||
					isEmailChangeModalOpen ||
					isDeleteConfirmationModalOpen
				}
				onClose={handleCloseModal}
				onConfirm={
					isEmailChangeModalOpen
						? handleEmailChangeConfirm
						: isDeleteConfirmationModalOpen
						? handleDeleteConfirm
						: currentAction === 'setPassword'
						? handlePasswordUpdateConfirm
						: null
				}
				content={
					isEmailChangeModalOpen ? (
						<>
							<label htmlFor='newEmail'>Ny e-post</label>
							<input
								id='newEmail'
								type='email'
								value={newEmail}
								placeholder='Ny Epost'
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<label htmlFor='confirmNewEmail'>
								Bekreft ny e-post
							</label>
							<input
								id='confirmNewEmail'
								type='email'
								value={confirmNewEmail}
								placeholder='Bekreft ny e-post'
								onChange={(e) =>
									setConfirmNewEmail(e.target.value)
								}
							/>
							{emailUpdateError && (
								<p style={{ color: 'red' }}>
									{emailUpdateError}
								</p>
							)}
						</>
					) : isDeleteConfirmationModalOpen ? (
						<p>
							Er du sikker på at du vil slette brukeren{' '}
							{userToEdit.first_name} {userToEdit.last_name} ?
						</p>
					) : currentAction === 'setPassword' ? (
						// Password update fields
						<>
							<label htmlFor='newPassword'>Nytt passord</label>
							<input
								id='newPassword'
								type='password'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder='Nytt passord'
							/>
							<label htmlFor='confirmNewPassword'>
								Bekreft nytt passord
							</label>
							<input
								id='confirmNewPassword'
								type='password'
								value={confirmNewPassword}
								onChange={(e) =>
									setConfirmNewPassword(e.target.value)
								}
								placeholder='Bekreft nytt passord'
							/>
							{passwordUpdateError && (
								<p style={{ color: 'red' }}>
									{passwordUpdateError}
								</p>
							)}
						</>
					) : // Here you can add the content for role change confirmation or any other action if needed
					null
				}
			>
				{/* This will only show the message about changing the role when it's not an email change or delete confirmation */}
				{!isEmailChangeModalOpen && !isDeleteConfirmationModalOpen && (
					<p>
						Er du sikker på at du vil endre rollen til{' '}
						{userToEdit?.first_name} til {selectedRole}?
					</p>
				)}
			</ConfirmationModal>
		</div>
	);
};

export default Users;
