import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FaExclamationCircle } from 'react-icons/fa';
import InputComponent from '../../components/InputComponent';
import TextareaComponent from '../../components/TextareaComponent';

// SCSS
import '../../assets/scss/sectionSeven.scss';

const SectionSeven = ({ control, register, formData }) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'websites',
	});

	return (
		<div className='sectionSeven'>
			<div className='header'>
				<h2>Ekstra informasjon</h2>
				<div className='border'></div>
				<small className='field-description-text'>
					Legg til ekstra informasjon om kampanjeperioden, private
					profiler og bannerannonsering
				</small>
			</div>

			<div className='flex-row'>
				<div className='flex-col'>
					<TextareaComponent
						label='Ønsker om kampanjeperiode'
						name='kampanjeperiode'
						className='kampanjeperiode'
						placeholder='Skriv inn ønske om kampanjeperiode'
						cols='40'
						rows='4'
						register={register}
					/>
					<small className='field-description-text'>
						Spesielle ønsker om kampanjeperiode? <br />
						(Oppstart er normalt 2 virkedager fra bestilling, før kl
						09:00)
						<FaExclamationCircle size='22' />
					</small>
				</div>
				<div className='flex-col'>
					<TextareaComponent
						label='Innlegg til story private profiler'
						name='storyText'
						className='story-text'
						placeholder='Skriv tekst til innlegg for story til private profiler i SOME'
						cols='40'
						rows='4'
						register={register}
					/>
					<small className='field-description-text'>
						Konsulenter i AC kan velge å legge utvalgte stillinger
						på egne storier på facebook og insta, og da må dette
						sendes over fra Adressa. <br />
						Skriv her dersom slikt innlegg ønskes.
						<FaExclamationCircle size='22' />
					</small>
				</div>
				{/* <div className='flex-col'>
					<TextareaComponent
						label='Bannerannonsering'
						name='bannerannonsering'
						className='bannerannonsering'
						placeholder='Skriv inn tekst til bannerannonsering'
						cols='40'
						rows='4'
						register={register}
						counter={false}
					/>

					<small className='field-description-text'>
						Tekst ved bannerannonsering. I de største formatene har
						vi plass til en kort setning i tillegg til "Vi søker ...
						". <br />
						Eksempel "til sin avdeling på hamar" eller "bli med på
						en unik reise!". Dersom det er lang stillingstittel
						og/eller det designmessig ikke vil se bra ut, vil dette
						falle bort.
						<FaExclamationCircle size='22' />
					</small>
				</div> */}
			</div>

			<input type='submit' value='Send bestilling' />
		</div>
	);
};

export default SectionSeven;
