import { Input } from 'postcss';
import InputComponent from '../../components/InputComponent';
import OrderPackages from '../../components/orderPackages';

const SectionTwo = ({
	register,
	formData,
	setValue,
	control,
	selectedLocation,
	selectedServices,
	setSelectedServices,
	getValues,
}) => {
	const packageOptions = [
		{
			value: 'Mediumliten',
			title: 'Medium',
			service: 'lite område',
		},
		{
			value: 'Mediumstor',
			title: 'Medium',
			service: 'stort område',
		},
		{
			value: 'Premium',
			title: 'Premium',
			service: 'og annet / skreddersøm',
		},
	];

	const selectedPackage = packageOptions.find(
		(option) => option.value === formData.packageChoice
	);
	console.log(selectedPackage);

	return (
		<div className='sectionTwo'>
			<div className='orderPackages'>
				<OrderPackages
					name='packageChoice'
					register={register}
					setValue={setValue}
					formData={formData}
					options={packageOptions}
					selectedLocation={selectedLocation}
					selectedServices={selectedServices}
					setSelectedServices={setSelectedServices}
					control={control}
					getValues={getValues}
				/>
				{selectedPackage && (
					<div className='contactPackage'>
						<div className='header'>
							<h2>
								Ta kontakt for <span>skreddersøm</span>
							</h2>
							<p>
								Legg igjen kontaktinformasjon. Du vil bli
								kontaktet i løpet av kort tid.
							</p>
						</div>

						<div className='inputs flex-row'>
							<div className='input'>
								<InputComponent
									label='Ditt navn'
									required
									name='packageContactName'
									type='text'
									register={register}
								/>
							</div>
							<div className='input'>
								<InputComponent
									label='Telefon'
									required
									name='packageContactPhone'
									type='tel'
									register={register}
								/>
							</div>
							<div className='input'>
								<InputComponent
									label='E-Post'
									required
									name='packageContactEmail'
									type='email'
									register={register}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SectionTwo;
